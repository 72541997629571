import styled from 'styled-components';
import { ButtonsWrapper } from './flex';
import { modalZIndex } from './variables';

export const ModalHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
`;

export const ModalBody = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  & > div {
    height: 100%;
    flex-grow: 1;
    overflow: visible;
    overflow-y: visible !important; // we want modal to take over the scrolling
  }
  position: relative;
  ${ButtonsWrapper} {
    position: sticky;
    bottom: 0;
    background: white;
  }
`;

export const XButton = styled.button`
  background-color: white;
  border: none;
  font-size: 34px;
  font-weight: 300;
  line-height: 24px;
  opacity: 0.6;
  transition: all 0.2s;
  padding: 0;
  &:hover {
    opacity: 1;
  }
`;

export const ModalWrapper = styled.div`
  border-radius: 3px;
  width: ${({ width }) => width || '600px'};
  height: ${({ height }) => height || ''};
  background: white;
  opacity: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  margin-bottom: auto;
  margin-top: 32px;
  max-height: calc(100vh - 64px);
  max-width: calc(100vw - 64px);
  overflow-y: auto;
  & > * {
    padding: 16px;
  }
  animation: 0.15s ease-out 0s 1 fadeIn, 0.2s slideIn;
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${modalZIndex}; // higher than user flow button
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.15s linear;
  animation: 0.15s ease-out 0s 1 fadeIn;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ModalFooter = styled(ButtonsWrapper)`
  margin-top: 32px;
`;
