import axios from 'axios';
import { dataToCamelCase, serpentize } from './utils';

/**
 * This file contains a bunch of functions for working with the backend.
 * They aren't wrapped into any class (object), because different packs
 * might use different functions, thus, after tree shaking (https://webpack.js.org/guides/tree-shaking/)
 * packs will contain only used functions.
 */

export function fetch(url, params = {}) {
  const headers = { 'X-Requested-With': 'XMLHttpRequest' };

  return axios.get(url, { params, headers }).then((res) => res.data);
}

// unfortunately, a `delete` name is reserved
export function destroy(url) {
  return axios.delete(url);
}

export function getDraft(id) {
  return axios.get(`/jobs/${id}/details/edit`);
}

export function fetchPaymentAddresses() {
  return fetch('/payment_addresses');
}

export function deletePaymentAddress(id) {
  return destroy(`/payment_addresses/${id}`);
}

export function createPaymentAddress(attrs) {
  return axios.post('/payment_addresses', { payment_address: attrs });
}

export function updatePaymentAddress(attrs) {
  return axios.put(`/payment_addresses/${attrs.id}`, {
    payment_address: attrs,
  });
}

export function fetchJobGroups() {
  return fetch('/job_groups');
}

export function fetchJobGroup(id) {
  return fetch(`/job_groups/${id}`);
}

export function deleteJobGroup(id) {
  return destroy(`/job_groups/${id}`);
}

export function createJobGroup(attrs) {
  return axios.post('/job_groups', { job_group: attrs });
}

export function updateJobGroup(id, attrs) {
  return axios.put(`/job_groups/${id}`, { job_group: attrs });
}

export function updateJobCategories(id, attrs) {
  return axios
    .put(`/jobs/${id}/categories`, { publication: attrs })
    .then((res) => res.data);
}

export function updateRevisionCategories(id, attrs) {
  return axios
    .put(`/revisions/${id}/categories`, { publication: attrs })
    .then((res) => res.data);
}

export function createFavoriteChannel(id) {
  return axios.post('/favorite_channels', { channel_id: id });
}

export function deleteFavoriteChannel(id) {
  return destroy(`/favorite_channels/${id}`);
}

export function acceptAgreement(cookies) {
  // cookies parameter doesn't get used by the server, but it gets logged
  return axios.patch('/accept-agreement', { cookies });
}

export function declineAgreement() {
  return axios.patch('/decline-agreement');
}

export function getNewFork(id) {
  return axios.get(`/jobs/${id}/new`);
}

export function deleteJob(id) {
  return destroy(`/jobs/${id}`);
}

export function cloneJob(id) {
  return axios.post(`/jobs/${id}/clone`);
}

export function saveOrder(jobId, orderId, items, modifiedAt) {
  const channelIds = items.map((item) => item.id);

  return axios.patch(`/jobs/${jobId}/channels`, {
    order_id: orderId,
    channel_ids: channelIds,
    modified_at: modifiedAt,
  });
}

export function updateCustomizations(customizations) {
  return axios.patch('/company/customizations', {
    customizations: customizations,
  });
}

export function fetchLocations(
  { page, perPage, includeArchived, searchTerm, order, orderBy },
  path = '/settings/locations'
) {
  return fetch(path, {
    page,
    per_page: perPage,
    include_archived: includeArchived,
    search_term: searchTerm || undefined,
    order: order,
    order_by: orderBy,
  });
}

export function createLocation(attrs) {
  return axios.post('/settings/locations', { location: attrs });
}

export function updateLocation(attrs) {
  return axios.put(`/settings/locations/${attrs.id}`, { location: attrs });
}

export function deleteLocation(id) {
  return destroy(`/settings/locations/${id}`);
}

export function archiveLocation(id) {
  return axios.put(`/settings/locations/${id}/archive`);
}

export function unarchiveLocation(id) {
  return axios.put(`/settings/locations/${id}/unarchive`);
}

export function fetchUserProfile() {
  return fetch('/settings/profile');
}

export function updateUserProfile(attrs) {
  return axios.put('/settings/profile', { profile: attrs });
}

export function updatePassword(attrs) {
  return axios.put('/settings/passwords', { change_password: attrs });
}

export function confirmOrder(jobId, attrs) {
  return axios.put(`/jobs/${jobId}/order/confirm`, { order: attrs });
}

export function fetchJobProfiles() {
  return fetch('/job_profiles');
}

export function fetchJobProfile(id) {
  return fetch(`/job_profiles/${id}`);
}

export function createJobProfile(attrs) {
  return axios.post('/job_profiles', { job_profile: attrs });
}

export function updateJobProfile(id, attrs) {
  return axios.put(`/job_profiles/${id}`, { job_profile: attrs });
}

export function updateCompanySettings(settings) {
  return axios.patch('/company/settings', settings);
}

export function fetchJobs({
  page,
  perPage,
  searchTerm,
  archived,
  orderBy,
  order,
  locationIds,
  userIds,
  frameContractIds,
}) {
  return fetch('/jobs', {
    page,
    archived,
    // don't send the order param as a string, otherwise, order confirmation requests couldn't
    // be logged in ElasticSearch
    order_direction: order,
    per_page: perPage,
    search_term: searchTerm,
    order_by: orderBy,
    location_ids: locationIds,
    user_ids: userIds,
    frame_contract_ids: frameContractIds,
  });
}

export function fetchPostings(jobId) {
  return fetch(`/jobs/${jobId}/postings`);
}

export function fetchJobPreview(jobId) {
  return fetch(`/jobs/${jobId}/preview`);
}

export function fetchRevisionPreview(revisionId) {
  return fetch(`/revisions/${revisionId}/preview`);
}

export function fetchAdTemplateSource(templateId) {
  return fetch(`/ad_templates/${templateId}/source`);
}

export function expirePosting(postingId) {
  return axios.put(`/postings/${postingId}/expire`);
}

export function archiveJob(id) {
  return axios.put(`/jobs/${id}/archive`);
}

export function setActiveCompany(companyId) {
  return axios.patch('/users/companies', { selected_company_id: companyId });
}

export async function uploadMediaAsset({
  file,
  mediaAssetType,
  onError,
  onSuccess,
  onUploadProgress,
  signal,
}) {
  // Request presigned URL from S3
  try {
    const options = {
      signal,
      onUploadProgress: onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();

    formData.append('file', file);
    formData.append('media_asset_type', mediaAssetType);
    formData.append('name', file.name);

    const { data } = await axios.post('/media_assets', formData, options);
    onSuccess && onSuccess(data);
    return data;
  } catch (error) {
    onError && onError(error);
  }
}

export const fetchMediaAssets = async (media_asset_type = 'header') =>
  fetch('/media_assets', { media_asset_type });

export const updateMediaAsset = (mediaAsset) =>
  axios.patch(`/media_assets/${mediaAsset?.id}`, mediaAsset);

export async function uploadLogo({
  file,
  onUploadProgress,
  onSuccess,
  onError,
  signal,
}) {
  const options = {
    signal,
    onUploadProgress: onUploadProgress,
    headers: {
      'Content-Type': file.type,
    },
  };

  const formData = new FormData();
  formData.append('company_logo[logo]', file);
  try {
    const { data } = await axios.patch('/company_logo', formData, options);
    onSuccess && onSuccess(data);
    return data;
  } catch (error) {
    onError && onError(error);
  }
}

export const deleteLogo = async () => destroy('/company_logo');

export function validateImage(url) {
  return axios.get('/file_size', { params: { url } });
}

export function updateSocialProfile(attrs) {
  return axios.patch('/company/social-profile', { social_profile: attrs });
}

export function fetchCareerSiteChannels() {
  return fetch('/channels/career-site');
}

export function saveCareerSiteChannels(ids) {
  return axios.patch('/company/save-career-sites', { ids });
}

export function updateFrameContract(frameContract) {
  return axios.patch(`/frame_contracts/${frameContract?.id}`, {
    frameContract,
  });
}

export function createFrameContract(frameContract) {
  return axios.post(`/frame_contracts`, { frameContract });
}

export function createRevision(values) {
  return axios.post(
    `/postings/${values.job_id}/${values.posting_id}/revision/new`,
    { publication: values }
  );
}

export function updateRevision(values) {
  return axios.patch(`/revisions/${values.id}/details`, {
    publication: values,
  });
}

export function createJob(values) {
  return axios.post('/jobs/new', { publication: values });
}

export function updateJob(values) {
  return axios.patch(`/jobs/${values.id}/details`, { publication: values });
}

export const signUp = (values) => axios.post('/signup', { signup: values });
export const signIn = (values) => axios.post('/signin', { signin: values });
export const requestResetPassword = (values) => axios.post('/resets', values);
export const resetPassword = ({ values, url }) => axios.put(url, values);

export async function fetchFrameContracts({
  contingentType: contingent_type,
  state,
  used,
}) {
  return await fetch('/frame_contracts', { contingent_type, state, used });
}

export function fetchCompanyUsers({ searchTerm, stateFilter }) {
  return fetch('/companies/users', {
    search_term: searchTerm,
    state_filter: stateFilter,
  });
}

export const URL = {
  favoriteChannelsList: '/companies/favorite_lists',
  supplier: '/onsite_apply_suppliers',
};

export const fetchFavoriteChannelsLists = () => fetch(URL.favoriteChannelsList);

export const createFavoriteChannelsList = (favoriteList) =>
  dataToCamelCase(
    axios.post(URL.favoriteChannelsList, serpentize(favoriteList))
  );

export const udpateFavoriteChannelsList = async ({
  id,
  ...favoriteChannelsList
}) =>
  dataToCamelCase(
    axios.patch(
      `${URL.favoriteChannelsList}/${id}`,
      serpentize(favoriteChannelsList)
    )
  );

export const deleteFvaoriteChannelsList = (favouriteListId) =>
  destroy(`${URL.favoriteChannelsList}/${favouriteListId}`);

export const removeChannelFromFavoritesList = ({ listId, channelId }) =>
  destroy(`${URL.favoriteChannelsList}/${listId}/channels/${channelId}`);

export const addChannelToFavoriteList = ({ listId, channelId }) =>
  axios.post(`${URL.favoriteChannelsList}/${listId}/channels`, {
    id: channelId,
  });

export const updateOnsiteApplySupplier = (supplier) =>
  dataToCamelCase(axios.patch(URL.supplier, serpentize(supplier)));
